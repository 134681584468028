<template>
  <div class="cost">
    <Header back="返回" title="研发项目" index="首页" titleOne="科创模块" titleTwo="研发项目" beforeTitle="项目情况表" />
    <div class="content">
      <h1>项目情况表</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="研发活动名称 " prop="rd_name">
          <el-input v-model="form.rd_name" placeholder="请输入研发活动名称"></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="start_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.start_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="截止时间" prop="end_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.end_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="技术领域" prop="technology_one">
          <el-select v-model="form.technology_one" placeholder="请选择" @change="getTechnology_two">
            <el-option v-for="item in one" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.technology_two" placeholder="请选择" @change="getTechnology_three">
            <el-option v-for="item in two" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.technology_three" placeholder="请选择">
            <el-option v-for="item in three" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="技术来源">
          <el-select v-model="form.technology_source" placeholder="请选择">
            <el-option label="大专院校" :value="1">大专院校</el-option>
            <el-option label="地方属科研院所" :value="2">地方属科研院所</el-option>
            <el-option label="其他企业技术" :value="3">其他企业技术</el-option>
            <el-option label="引进技术本企业消化创新" :value="4">引进技术本企业消化创新</el-option>
            <el-option label="国外技术" :value="5">国外技术</el-option>
            <el-option label="企业自有技术" :value="6">企业自有技术</el-option>
            <el-option label="中央属科研院所" :value="7">中央属科研院所</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="研发人数(人)" style="width: 100%">
          <el-input v-model="form.rd_personnel" placeholder="请输入研发人数(人)"></el-input>
        </el-form-item>
        <el-form-item label="研发经费总预算(万元)">
          <el-input v-model="form.total_budget" placeholder="请输入研发经费总预算(万元)"></el-input>
        </el-form-item>
        <el-form-item label="研发经费近三年总支出(万元)">
          <el-input v-model="form.three_year_budget" placeholder="请输入研发经费近三年总支出(万元)"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="第一年(万元)">
          <el-input v-model="form.first_pay" placeholder="请输入第一年(万元)"></el-input>
        </el-form-item>
        <el-form-item label="第二年(万元)">
          <el-input v-model="form.second_pay" placeholder="请输入第二年(万元)"></el-input>
        </el-form-item>
        <el-form-item label="第三年(万元)">
          <el-input v-model="form.third_pay" placeholder="请输入第三年(万元)"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="目的及组织实施方式（限400字）" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.technology"></el-input>
        </el-form-item>
        <el-form-item label="核心技术及创新点（限400字）" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.advantage"></el-input>
        </el-form-item>
        <el-form-item label="取得的阶段性成果(限400字）" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.effect"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>7
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      labelPosition: "top",
      form: {
        rd_name: "",
        start_time: "",
        end_time: "",
        technology_one: "",
        technology_two: "",
        technology_three: "",
        technology_source: "",
        rd_personnel: "",
        total_budget: "",
        three_year_budget: "",
        first_year: "",
        second_year: "",
        third_year: "",
        technology: "",
        advantage: "",
        effect: "",
      },
      one: [],
      two: [],
      three: [],
      formRule: {
        rd_name: [
          {
            required: true,
            message: "请输入研发活动名称",
            trigger: "blur",
          },
        ],
        start_time: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        end_time: [
          {
            required: true,
            message: "请选择截止时间",
            trigger: "change",
          },
        ],
        technology_one: [
          {
            required: true,
            message: "请选择技术领域",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    "form.technology_one": {
      handler (val, oval) {
        this.getTechnology_two(val);
      },
      deep: true,
    },
    "form.technology_two": {
      handler (val, oval) {
        this.getTechnology_three(val);
      },
      deep: true,
    },
  },
  created () {
    this.getTechnology_one();
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/rd/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    getTechnology_one () {
      this.axios.get("/api/tech/get_first_level").then((res) => {
        this.one = res.data;
      });
    },
    getTechnology_two (id) {
      this.axios
        .get("/api/tech/get_children_list", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.two = res.data;
        });
    },
    getTechnology_three (id) {
      this.axios
        .get("/api/tech/get_children_list", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.three = res.data;
        });
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        this.com_id = this.form.com_id;
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/rd/store", this.form)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.$router.push("/rd/index");
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
